<template>
<div class="w">
  <div class="crumbs">
    <router-link to="/">首页</router-link>
    <span>/</span>
    行业体系
    <span>/</span>
    <em>{{info.name}}</em>
  </div>
  <section class="container">
    <div class="list">
      <div class="list-name">
        体系结构
      </div>
      <div class="list-box">
        <div class="list-title" @click="changeShow('one')">
          <span>省级</span><i :class="{show: show.one}"></i>
        </div>
        <ul v-show="show.one">
          <li v-for="item of list1" :key="item.id" :class="{'active':item.id === activeID}" @click="clickHandle(item.id)">{{item.name}}</li>
        </ul>
        <div class="list-title" @click="changeShow('two')">
          <span>市级</span><i :class="{show: show.two}"></i>
        </div>
        <ul v-show="show.two">
          <li v-for="item of list2" :key="item.id" :class="{'active':item.id === activeID}" @click="clickHandle(item.id)">{{item.name}}</li>
        </ul>
        <div class="list-title" @click="changeShow('three')">
          <span>县级</span><i :class="{show: show.three}"></i>
        </div>
        <ul v-show="show.three">
          <li v-for="item of list3" :key="item.id" :class="{'active':item.id === activeID}" @click="clickHandle(item.id)">{{item.name}}</li>
        </ul>
      </div>

    </div>
    <div class="content" v-if="info.id">
      <div class="content">
        <div class="title">{{info.name}}</div>
        <div class="info">
          <span class="info-source" v-if="info.source">来源：{{info.source}}</span>
          <span>发布时间：{{info.createTime}} </span>
        </div>
        <div class="detail">
          <h4>机构简介：</h4>
          <div class="detail-body">{{info.body}}</div>
          <h4>机构详情：</h4>
          <div v-html="info.detail"></div>
        </div>
      </div>
    </div>

  </section>
</div>
</template>

<script>
import { defineComponent, ref, onMounted, computed, watch } from 'vue'
import { yncyIndustryList, yncyIndustryDetail } from '../api'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'Industry',
  setup () {
    const list1 = ref([])
    const list2 = ref([])
    const list3 = ref([])
    const activeID = ref(0)

    const getIndustryList = (newsCategory) => {
      yncyIndustryList({ newsCategory, pageSize: 1000 }).then(res => {
        res.result.records.forEach(item => {
          if (newsCategory === 0) {
            list1.value.push(item)
          } else if (newsCategory === 1) {
            list2.value.push(item)
          } else if (newsCategory === 2) {
            list3.value.push(item)
          }
        })
      })
    }

    const info = ref({})
    const clickHandle = (id) => {
      yncyIndustryDetail({ id }).then(res => {
        info.value = res.result
        activeID.value = id
      })
    }

    const show = ref({
      one: true,
      two: false,
      three: false
    })

    const first = computed(() => {
      if (list1.value.length > 0 && show.value.one) {
        return list1.value[0].id
      } else if (list2.value.length > 0 && show.value.two) {
        return list2.value[0].id
      } else if (list3.value.length > 0 && show.value.three) {
        return list3.value[0].id
      } else {
        return 0
      }
    })
    watch(first, (val) => {
      console.log('=========>', val)
      activeID.value = val
      clickHandle(val)
    })

    const changeShow = (num) => {
      show.value[num] = !show.value[num]
    }

    const route = useRoute()

    onMounted(() => {
      getIndustryList(0)
      getIndustryList(1)
      getIndustryList(2)
      for (const key in show.value) {
        show.value[key] = false
      }
      show.value[route.query.type || 'one'] = true
    })

    return { list1, list2, list3, clickHandle, info, show, changeShow, activeID }
  }
})
</script>

<style lang="scss" scoped>
.content{
  border: 1px solid #DDDDDD;
  padding: 40px 40px 60px;
}
.detail-body{
  font-size: 16px;
  line-height: 20px;
}
.title{
  text-align: center;
  font-size: 22px;
}
.info{
  line-height: 38px;
  text-align: center;
  color: #999999;
  border-bottom: 1px dotted #999999;
  margin-bottom: 60px;
  &-source{
    margin-right: 20px;
  }
}

.container{
  margin-bottom: 90px;
  display: flex;
}
.content{
  flex: 1;
}
.list{
  width: 246px;
  border: 1px solid #DDDDDD;
  margin-right: 18px;
  height: 100%;

  &-box{
    padding: 20px 15px;
  }

  ul{
    margin-bottom: 20px;
  }

  li{
    cursor: pointer;
    height: 47px;
    line-height: 47px;
    border-bottom: 1px dotted #DDDDDD;
    width: 210px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;

    &.active, &:hover{
      color:#07C160;
    }

    &:last-child{
      border-bottom: 0;
    }
  }

  &-title{
    border-left: 5px solid #07C160;
    font-size: 18px;
    padding-left: 6px;
    color: #333333;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;

    i{
      display: block;
      width: 13px;
      height: 8px;
      background: url(../assets/arrow.png) no-repeat;
      background-size: 13px 8px;

      &.show{
        transform: rotate(180deg);
      }
    }
  }

  &-name{
    height: 64px;
    line-height: 64px;
    text-align: center;
    color: #ffffff;
    background: #07C160;
    font-size: 22px;
    font-weight: 700;
  }
}
</style>
<style lang="scss">
.crumbs{
  em{
    font-style: normal;
    color: #07C160;
  }
}
</style>
